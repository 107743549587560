$primary: #1b1464;/* MAIN COLOR */
$secondary: #55016b; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
$bgcolor: $primary;

@import url('https://fonts.googleapis.com/css?family=Oswald|PT+Sans');

html, body {
    height: 100%;
}

h1,h2,h3 {
   font-family: 'Oswald', sans-serif; 
}

p {
   font-family: 'PT Sans', sans-serif;
   font-size: 1.6em;
   @media (max-width: 991px) {
    font-size: 1.3em;
   } 
   @media (max-width: 767px) {
    font-size: 1em;
   }
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

i.fa {
    font-size: 4em;
    padding: 10px;
}
/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}


// @function nav-colors($bgcolor) {
//   @if $bgcolor == black or $bgcolor == white {
//       @if (lightness($bgcolor) > 70) {
//         @return #000000; 
//       } @else {
//         @return #ffffff; 
//       }  
//   } @else {
//     $complement: complement($bgcolor);
//     $lightened: lighten($complement, 70%);
//     @return $lightened;
//   }

// }

@function invert-colors($bgcolor) {
  @if (lightness($bgcolor) > 70) {
    @return #000000; 
  } @else {
    @return #ffffff; 
  } 
}

.navbar-default {
    background: $bgcolor;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: invert-colors($bgcolor);
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 3px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 18px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background: transparent;
}
.navbar-default .navbar-toggle {
    .icon-bar {
        background-color: $wht;
    }
}
/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: lighten($primary, 30%);
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 30%);
        color: $wht;
        text-decoration: none;
        @media (max-width: 1024px) {
            background: lighten($primary, 30%);
            color: $wht;
            box-shadow: none;
        }
    }
    &:focus {
        background: lighten($primary, 30%);
        color: $wht;
        text-decoration: none;
        box-shadow: none;  
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $bgcolor;
    color: invert-colors($bgcolor);
    font-size: 15px;
    a {
        color: invert-colors($bgcolor);
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        padding: 0 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

.top-pad {
    padding: 0.5em;
    background: white;
    font-family: 'PT Sans', sans-serif;
    li {
         font-family: 'PT Sans', sans-serif;
    }
    h1,h2,h3,h4 {
        @media (max-width: 991px) {
            font-size: 1em;
        }
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 13px;
    }
    
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}

.banner {
    position: relative;
    h1 {
        color: white;
        position: absolute;
        z-index: 2;
        top: 50%;
        font-size: 5em;
        margin: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 1199px) {
            font-size: 3.75em;
        }
        @media (max-width: 991px) {
            font-size: 3em;
        }
        @media (max-width: 767px) {
            font-size: 2em;
        }
    }
    video {
        width: 100%;
        height: 100%;
        display: block;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.introTitle {
    position: relative;
    margin-top: 50px;
    display: inline-block;
    z-index: 1;
    font-size: 3.5em;
    color: $primary;
    text-transform: uppercase;
    @media (max-width: 991px) {
        font-size: 2.5em;
    }
    @media (max-width: 767px) {
        font-size: 2em;
    }
    &:before {
        position: absolute;
        content: 'Find Love Now';
        top: -50%;
        right: 0%;
        color: lighten(grey, 43%);
        z-index: -1;
        font-size: 1em;
        text-transform: lowercase;
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.introTitle2 {
    position: relative;
    margin-top: 50px;
    display: inline-block;
    z-index: 1;
    font-size: 3.5em;
    color: $primary;
    text-transform: uppercase;
    @media (max-width: 991px) {
        font-size: 2.5em;
    }
    @media (max-width: 767px) {
        font-size: 2em;
    }
    &:before {
        position: absolute;
        content: 'Simple and Easy';
        top: -50%;
        right: 0%;
        color: lighten(grey, 43%);
        z-index: -1;
        font-size: 1em;
        text-transform: lowercase;
        @media (max-width: 991px) {
            display: none;
        }
    }
}

a.btn {
    @include btn;
}

.bg {
    background: linear-gradient(
         rgba($primary, 0.5),
         rgba($primary, 0.5)
        ),url(../img/banner.jpg) no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 270px 0 150px 0;
    -webkit-clip-path: polygon(100% 0%, 0% 30%, 0% 100%, 100% 100%);
    clip-path: polygon(100% 0%, 0% 30%, 0% 100%, 100% 100%);
    color: white;
    background-attachment: fixed;
    @media (max-width: 1024px) {
        background: linear-gradient(
             rgba($primary, 1),
             rgba($secondary, 1)
            );
        clip-path: none;
        -webkit-clip-path: none;
        padding: 100px 0;
    }
    @media (max-width: 767px) {
        padding: 50px 0;
    }
    h1 {
        @media (max-width: 767px) {
            font-size: 2em;
        }
    }
    .txtBox {
        @media (max-width: 991px) {
            p {
                max-width: 450px;
                width: 100%;
                margin: 0 auto;
            }
        }
    }
}
.solidBg {
    background: $secondary;
    .couple {
        @media (max-width: 991px) {
            max-width: 400px;
            width: 100%;
            display: block;
            margin: 0 auto 10px auto;
        }
    }
}

// .tble {
//     display: table;
//     table-layout: fixed;
//     @media (max-width: 991px) {
//         display: block;
//     }
//     .tble-cell {
//         display: table-cell;
//         vertical-align: middle;
//         float: none;
//         @media (max-width: 991px) {
//             display: block;
//         }
//     }
// }
.mt {
    margin-top: 75px;
    @media (max-width: 1199px) {
        margin-top: 115px;
    }
    @media (max-width: 991px) {
        margin-top: 0;
    }
}
.padTopBtm {
    padding: 50px 15px;
}

.circle {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    @media (max-width: 991px) {
        max-width: 300px;
        width: 100%;
    }
}

.btmSection {
    .padTopBtm2 {
        padding: 100px 0;
        @media (max-width: 991px) {
            padding: 20px 0;    
        }
    }
    p {
        font-size: 1.5em;
        @media (max-width: 767px) {
            font-size: 1em;
        }
    }
}